import '../styles.min.css';
import React from 'react';

function SideBarOption(props) {
    return (
        <div id={props.id} onClick={props.clickEvent}>
            <span className="material-icons-outlined">{props.icon}</span>
            <h4>{props.text}</h4>
        </div>
    )
}

export default SideBarOption;