import '../styles.min.css';
import TestContext from '../context/TestContext';
import React, { useContext } from 'react';

function SideBarTabs(props) {
    const {updateType} = useContext(TestContext);
    return (
        <div id="sidebar-tabs">
            <div className={props.status === "TESTS" ? "tab active" : "tab"} onClick={() => updateType("TESTS")}>
                <span className="material-icons-outlined">smart_toy</span>
                Tests
            </div>
            <div className={props.status === "COMPONENTS" ? "tab active" : "tab"} onClick={() => updateType("COMPONENTS")}>
                <span className="material-icons-outlined">style</span>
                Components
            </div>
            <div className={props.status === "COMMANDS" ? "tab active" : "tab"} onClick={() => updateType("COMMANDS")}>
                <span className="material-icons-outlined">speed</span>
                Commands
            </div>
        </div>
    )
}

export default SideBarTabs;