import '../styles.min.css';
import React from 'react';

function ContextMenu(props) {
    return (
        <div id="context-menu" style={{display: props.toggle ? "inline-grid" : "none", top: props.y, left: props.x}}>
            <ul>
                <li onClick={props.copy}>Copy Name</li>
                <li>Flag</li>
                <li onClick={props.used} style={{display: props.status !== "TESTS" ? "block" : "none"}}>Show Usage</li>
                <li onClick={props.compare}>Compare With Base</li>
            </ul>
        </div>
    )
}

export default ContextMenu;