import './App.min.css';
import React, { useState, useEffect } from 'react';
import Editor, { DiffEditor, useMonaco } from "@monaco-editor/react";
import SideBar from './components/SideBar'
import TestContext from './context/TestContext';
import {convertTest} from './assets/data-layer'


function App() {
  const [currentState, updateState] = useState();
  const [showDiff, triggerDiff] = useState({diff: {}, toggle: false});
  const [typeStatus, updateTypeStatus] = useState("TESTS");
  const monaco = useMonaco();

  const updateTest = test => {
    updateState(test);
    triggerDiff({diff: {}, toggle: false})
  };

  const updateType = test => {
    updateTypeStatus(test);
  };

  const updateDiffTrigger = diff => {
    triggerDiff(diff);
  };

  useEffect(() => {
    if (monaco) {
      // Register a new language
      monaco.languages.register({ id: 'RobotFramework' });

      // Register a tokens provider for the language
      monaco.languages.setMonarchTokensProvider('RobotFramework', {
        tokenizer: {
          root: [
            [/\*{3}.*/, "custom-line-type"],
            [/(Resource).*/, "custom-resource"],
            [/(Library).*/, "custom-library"],
            [/\${(.*?)}/, "custom-variable"],
            [/(= )(.*?)(\s{4})/, "custom-name"],
            [/\s{4}Comment.*/, "custom-comment"],
          ]
        }
      });

      // monaco.editor.setModelLanguage(editor.getModel(), 'RobotFramework');

      // Define a new theme that contains only rules that match this language
      monaco.editor.defineTheme('RobotTheme', {
        base: 'vs',
        inherit: false,
        rules: [
          { token: 'custom-line-type', foreground: 'b5b5b5' },
          { token: 'custom-resource', foreground: '4287f5'},
          { token: 'custom-library', foreground: '42adf5' },
          { token: 'custom-name', foreground: "eda16b", fontStyle: "bold"},
          { token: 'custom-variable', foreground: 'f531ba' },
          { token: 'custom-comment', foreground: '737373', fontStyle: "bold" }
        ]
      });
      monaco.editor.setTheme("RobotTheme");
    }
  }, [monaco]);

  const editorMounted = (editor, monaco) => {

  }

  return (
    <TestContext.Provider value={{currentState, updateTest, updateType, updateDiffTrigger}}>
      <div id="core">
        <SideBar status={typeStatus}/>
          <div id="editor-wrap">
            {
              showDiff.toggle || 
              <Editor
                id="editor"
                width="100%"
                height="100%"
                defaultLanguage="RobotFramework"
                defaultValue={currentState}
                value={currentState}
                onMount={editorMounted}
                theme='myCustomTheme'
              />
            }
            {
              showDiff.toggle && 
              <DiffEditor
                id="editor"
                width="100%"
                height="100%"
                defaultLanguage="RobotFramework"
                original={convertTest(showDiff.diff['client'])}
                modified={convertTest(showDiff.diff['base'])}
                onMount={editorMounted}
                theme='myCustomTheme'
              />
            }
           </div>
      </div>
    </TestContext.Provider>
  );
}

export default App;
