import '../styles.min.css';
import SideBarData from './SideBarData'
import TestContext from '../context/TestContext';
import React, { useContext, useState } from 'react';
import {FindTest, convertTest, FindComponents, FindCommands, ListAllOrgs, ListAllVersions, GetFirstVersion, GetTests, GetComponents, GetCommands, GetTestsWithComponents, GetBaseDiff} from '../assets/data-layer'

import SideBarTabs from './SideBarTabs'
import SideBarOption from './SideBarOption'
import ContextMenu from './ContextMenu'

function SideBar(props) {
    const [context, triggerContext] = useState({x: 0, y: 0, status: false});

    const [selectOrg, triggerOrg] = useState(false);
    const [activeOrg, changeOrg] = useState("Fidelity Life");

    const [selectVers, triggerVers] = useState(false);
    const [activeVers, changeVers] = useState("10.0.0.21");

    
    const [usage, triggerUsage] = useState(false);

    const [usageList, updateUsageList] = useState({});

    const {updateTest} = useContext(TestContext);
    const {updateDiffTrigger} = useContext(TestContext);

    function searchTests(e) {
        let results = ""
        if(props.status === "TESTS")
            results = FindTest(e.target.value);

        else if(props.status === "COMPONENTS")
            results = FindComponents(e.target.value);

        else if(props.status === "COMMANDS")
            results = FindCommands(e.target.value);

        if(results && results.length > 0) {
            updateTest(convertTest(results[0]))
        }
    }
    function triggerContextMenu(e, name, suite) {
        e.preventDefault()
        triggerContext({x: e.pageX, y: e.pageY, status: true, name: name, suite: suite})
        document.addEventListener("click", hideContext)
    }

    function hideContext() {
        triggerContext({x: context.x, y: context.y, status: false, name: context.name, suite: context.suite})
        document.removeEventListener("click", hideContext)
    }

    function copyName() {
        navigator.clipboard.writeText(context.name);
    }

    function compareBase() {
        const diff = GetBaseDiff(props.status, activeOrg, activeVers, context.name, context.suite);
        if(diff["status"] === "PASS")
            updateDiffTrigger({diff: diff, toggle: true})
        else
            alert(context.name + " does not exist in base test version " + activeVers)
    }

    function findUsage() {
        const resp = GetTestsWithComponents(activeOrg, activeVers, context.name);
        if(Object.keys(resp).length > 0) {
            updateUsageList(resp);
            triggerUsage(true);
        }
    }

    return (
        <nav id="sidebar">

            <ContextMenu status={props.status} toggle={context.status} x={context.x} y={context.y} copy={copyName} used={findUsage} compare={compareBase}/>

            <div id="sidebar-search">
                <input type="text" placeholder="Find something" onInput={e => searchTests(e)}/>
            </div>

            <SideBarTabs status = {props.status} />

            <div id="hierarchy">
                { props.status === "TESTS" && Object.keys(GetTests(activeOrg, activeVers)).map((d, i) => <SideBarData key={i} suite={d} org={activeOrg} v={activeVers} status={props.status} trigger={(e, name, suite) => triggerContextMenu(e, name, suite)}/>) }
                { props.status === "COMPONENTS" && Object.keys(GetComponents(activeOrg, activeVers)).map((d, i) => <SideBarData key={i} suite={d} org={activeOrg} v={activeVers} status={props.status} trigger={(e, name, suite) => triggerContextMenu(e, name, suite)}/>) }
                { props.status === "COMMANDS" && Object.keys(GetCommands(activeOrg, activeVers)).map((d, i) => <SideBarData key={i} suite={d} org={activeOrg} v={activeVers} status={props.status} trigger={(e, name, suite) => triggerContextMenu(e, name, suite)}/>) }
            </div>

            <div id="sidebar-organisation">
                <div id="org-select-popup" style={{display: selectOrg ? "inline-block" : "none"}}>
                    <div id="org-pop-header">
                        <h3>Choose an organisation</h3>
                        <span className="material-icons-outlined" onClick={() => triggerOrg(false)}>close</span>
                    </div>
                    <ul>
                        {
                            Object.keys(ListAllOrgs()).map((org, i) => <li key={i} className={activeOrg === org ? "active" : "inactive"} onClick={() => {changeOrg(org); triggerOrg(false); changeVers(GetFirstVersion(org))}}>{org}</li>)
                        }
                    </ul>
                </div>

                <div id="vers-select-popup" style={{display: selectVers ? "inline-block" : "none"}}>
                    <div id="org-pop-header">
                        <h3>Choose a version for {activeOrg}</h3>
                        <span className="material-icons-outlined" onClick={() => triggerVers(false)}>close</span>
                    </div>
                    <ul>
                        {
                            ListAllVersions({activeOrg}).map((v, i) => <li key={i} className={activeVers === v ? "active" : "inactive"} onClick={() => {changeVers(v); triggerVers(false)}}>{v}</li>)
                        }
                    </ul>
                </div>

                <div id="usage-select-popup" style={{display: usage ? "inline-block" : "none"}}>
                    <div id="org-pop-header">
                        <h3>Component Usage</h3>
                        <span className="material-icons-outlined" onClick={() => triggerUsage(false)}>close</span>
                    </div>
                    <ul>
                        {Object.keys(usageList).map(suite => {
                            return <div>
                                <h4>{suite}</h4>
                                {Object.values(usageList[suite]).map((test, i) => <li key={i} className="usage-list" onClick={() => updateTest(convertTest(test))}>{test.name}</li>)}
                            </div>
                        })}
                    </ul>
                </div>

                <SideBarOption id="change-org" icon={"store"} text={activeOrg} clickEvent={() => triggerOrg(true)}/>
                <SideBarOption id="change-version" icon={"event"} text={activeVers} clickEvent={() => triggerVers(true)}/>

            </div>
        </nav>
    )
}

export default SideBar;