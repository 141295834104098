import '../styles.min.css';
import React, { useContext } from 'react';
import TestContext from '../context/TestContext';

import {FormatSuite, GetTestsFromSuite, GetComponentsFromSuite, GetCommandsFromSuite, convertTest, FormatCompSuite, FormatCommSuite} from '../assets/data-layer';

function SideBarData(props) {
    const {updateTest} = useContext(TestContext);

    let tests = ""
    if(props.status === "TESTS") {
        tests = GetTestsFromSuite(props.org, props.v, props.suite);
    }else if(props.status === "COMPONENTS") {
        tests = GetComponentsFromSuite(props.org, props.v, props.suite);
    }else if(props.status === "COMMANDS") {
        tests = GetCommandsFromSuite(props.org, props.v, props.suite);
    }

    const testsCount = tests.length;
    return (
        <div className="detail-section">
            <h5 style={testsCount === 0 ? {cursor: 'not-allowed'} : {cursor: 'pointer'}} onClick={() => updateTest(showAll(props.status, props.org, props.v, props.suite))}>{props.suite} ({testsCount})</h5>
            <ul>
                {
                    tests && Object.values(tests).map((t, i) => <li key={i} title={t.documentation} onContextMenu={e => props.trigger(e, t.name, props.suite)} onClick={() => updateTest(convertTest(t))}>
                            {props.status !== "TESTS" && (t.tags["edited"] === "true" ? "" : "🔗")}
                            {props.status !== "TESTS" && (t.tags["deprecated"] === "true" ? "📒" : "")}
                            {t.name}
                        </li>)
                }
            </ul>
        </div>
    )
}

function showAll(status, org, vers, suite) {
    if(status === "TESTS")
        return FormatSuite(org, vers, suite)
    else if(status === "COMPONENTS")
        return FormatCompSuite(org, vers, suite)
    else if(status === "COMMANDS")
        return FormatCommSuite(org, vers, suite)
}

export default SideBarData;