import TestData from './tests.json'
import ComponentData from './components.json'
import KeywordData from './commands.json'
import OrganisationList from './formatted/organizations.json'

export const ListAllOrgs = data => {
    return OrganisationList
}

export const ListAllVersions = organisation => {
    return OrganisationList[organisation.activeOrg]
}

export const GetFirstVersion = organisation => {
    const versions = OrganisationList[organisation]
    return versions[versions.length-1]
}

export const GetTests = (org, vers) => {
    const resp = require(`./formatted/${org}/${vers}/tests.json`)
    return resp
}

export const GetComponents = (org, vers) => {
    const resp = require(`./formatted/${org}/${vers}/components.json`)
    return resp
}

export const GetCommands = (org, vers) => {
    const resp = require(`./formatted/${org}/${vers}/commands.json`)
    return resp
}

export const GetBaseDiff = (status, org, vers, name, suite) => {
    const file = status.toLowerCase()
    let passed = false
    let output = {
        "status": "PASS",
        "client": {},
        "base": {}
    }
    let resp = require(`./formatted/${org}/${vers}/${file}.json`)
    Object.values(file === "tests" ? resp[suite]["Tests"] : resp[suite]).forEach(data => {
        if(data["name"] === name) {
            output["client"] = data
        }
    })

    const resp2 = require(`./formatted/thetestmart/${vers}/${file}.json`)
    if(file !== "tests" || (file === "tests" && [suite] in resp2 && ["Tests"] in resp2[suite])) {
        Object.values(file === "tests" ? resp2[suite]["Tests"] : resp2[suite]).forEach(data => {
            if(data["name"] === name) {
                output["base"] = data
                passed = true
            }
        })
    }
    if(passed === false) {
        console.log("Test does not exist in base tests for version ", vers)
        return {status: "FAIL"}
    }else
        return output
}

export const GetTestsWithComponents = (org, vers, comp) => {
    let output = {}
    const resp = require(`./formatted/${org}/${vers}/tests.json`)
    Object.keys(resp).forEach(suite => {
        resp[suite]["Tests"].forEach(test => {
            test["script"].forEach(line => {
                if(line["command"] === comp) {
                    if(Array.isArray(output[suite]))
                        output[suite].push(test)
                    else
                        output[suite] = [test]
                }
            })
        })
    })
    return output
}

export const DataSource = {
    TESTS: TestData,
    COMPONENTS: ComponentData,
    COMMANDS: KeywordData
}

export const GetTestsFromSuite = (org, vers, suite) => {
    const resp = require(`./formatted/${org}/${vers}/tests.json`)
    return resp[suite]["Tests"]
}

export const GetComponentsFromSuite = (org, vers, suite) => {
    const resp = require(`./formatted/${org}/${vers}/components.json`)
    return resp[suite]
}

export const GetCommandsFromSuite = (org, vers, suite) => {
    const resp = require(`./formatted/${org}/${vers}/commands.json`)
    return resp[suite]
}

export const FormatSuite = (org, vers, suite) => {
    const resp = require(`./formatted/${org}/${vers}/tests.json`)
    const settings = resp[suite]["Settings"].join('')
    const tests = resp[suite]["Tests"].map(t => convertTest(t))
    return settings + "\n" + tests.join("\n\n")
}

export const FormatCompSuite = (org, vers, suite) => {
    const resp = require(`./formatted/${org}/${vers}/components.json`)
    const tests = resp[suite].map(t => convertTest(t))
    return tests.join("\n")
}

export const FormatCommSuite = (org, vers, suite) => {
    const resp = require(`./formatted/${org}/${vers}/commands.json`)
    const tests = resp[suite].map(t => convertTest(t))
    return tests.join("\n")
}

export const FindTest = testName => {
    testName = testName.toLowerCase()
    const data = DataSource["TESTS"]
    for(let i = 0; i < Object.keys(data).length; i++) {
        const key = Object.keys(data)[i]
        const suite = data[key]["Tests"]
        const results = suite.filter((t, index) => t["name"].toLowerCase() === testName)
        if(results !== undefined && results.length > 0)
            return results
    }
}

export const FindComponents = testName => {
    testName = testName.toLowerCase()
    const data = DataSource["COMPONENTS"]
    for(let i = 0; i < Object.keys(data).length; i++) {
        const key = Object.keys(data)[i]
        const suite = data[key]
        const results = suite.filter((t, index) => t["name"].toLowerCase() === testName)
        if(results !== undefined && results.length > 0)
            return results
    }
}

export const FindCommands = testName => {
    testName = testName.toLowerCase()
    const data = DataSource["COMMANDS"]
    for(let i = 0; i < Object.keys(data).length; i++) {
        const key = Object.keys(data)[i]
        const suite = data[key]
        const results = suite.filter((t, index) => t["name"].toLowerCase() === testName)
        if(results !== undefined && results.length > 0)
            return results
    }
}

export const convertTest = test => {
    let output = ""
    output += test["name"] + "\n"
    if("tags" in test)
        output += "    [Tags]    " + Object.keys(test["tags"]).map(tag => tag + ":" + test["tags"][tag]).join("    ") + "\n"
    if("args" in test)
        output += "    [Arguments]    " + Object.keys(test["args"]).map(tag => (tag + ((test["args"][tag].length > 0) ? "=" + test["args"][tag] : ""))).join("    ") + "\n"
    if("documentation" in test){
        output += "    [Documentation]    " + test["documentation"].trim().replace(/(?:\r\n|\r|\n)/g, '\n    ...') + "\n"
    }
    test["script"].forEach(ob => {
        if(ob["returns"] !== "") {
            output += "    " + ob["returns"] + "="
        }else{
            output += "    " + ob["command"]
        }
        output += "    " + ob["args"].join("    ")
        output += "\n"
    })
    return output
}